<template>
  <div>
    <v-btn
      v-if="item.actions.canEdit"
      color="primary"
      class="mr-2 prd-crud-edit-button px-1"
      x-small
      @click="goToEdit(item.id)"
      depressed
    >
      <v-icon small>mdi-arrow-right-thick</v-icon>
    </v-btn>
    <v-btn
      v-if="item.actions.canDelete"
      tile
      icon
      depressed
      x-small
      color="grey"
      @click="$emit('deleteItem', item.id)"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    crudActions: { type: Object, required: true },
  },
  methods: {
    goToEdit(itemId) {
      if (!itemId) {
        this.$notifyError(
          null,
          "El elemento que intentas abrir no existe. Recarga la página y vuélvelo a intentar."
        );
        return;
      }
      const routeName = this.$route.name;
      const isTabCrud = routeName.includes("-");
      const editItemRouteName = isTabCrud ? routeName.split("-")[1] + "Edit" : routeName + "Edit";

      this.$router.push({ name: editItemRouteName, params: { id: itemId } });
    },
  },
};
</script>

<style></style>
